<template>
    <div class="
      table-title
      d-flex
      p-3
      flex-xxl-row
      flex-xl-row
      flex-lg-row
      flex-md-row
      flex-sm-column
      flex-column
    ">
        <div class="flex-grow-1 my-auto">
            <p class="mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-0 mb-sm-3 mb-3">
                {{ data.name }}
            </p>
        </div>
    </div>

    <div class="table-responsive">
        <table class="table table-striped table-hover">
            <thead class="table-header">
                <tr>
                    <th v-for="(item, index) in data.columns" :key="data.name + '-head-' + index" scope="col">
                        <a v-if="item.sort" href="#" @click.prevent="$emit('sorting', item)">
                            {{ item.title }}
                            <font-awesome-icon :icon="['fas', 'sort']" />
                        </a>
                        <span v-else>{{ item.title }}</span>
                    </th>
                    <th v-if="data.showAction" scope="col">Actions</th>
                </tr>
            </thead>
            <tbody v-if="data.dataSet.length > 0">
                <tr v-for="(row, rowIndex) in data.dataSet" :key="data.name + '-row-' + rowIndex" :class="row.css_classes">
                    <td v-for="(column, columnIndex) in data.columns" data-bs-toggle="tooltip" data-bs-placement="top"
                        :title="column.tooltip ? row[column.key] : ''"
                        :key="data.name + '-column-' + rowIndex + columnIndex">
                        <router-link v-if="column.type === 'link'" :class="textTruncate(column) ? 'd-inline-block text-truncate' : ''
                            " :style="textTruncate(column) ? 'max-width: 300px;' : ''" :to="column.modifier(row[column.key], row)">
                            {{ row[column.key] }}
                        </router-link>

                        <router-link v-else-if="column.type === 'icon'" data-bs-toggle="tooltip" title="Permission"
                            tag="button" :class="column.show && column.show ? '' : 'disable-router-link'"
                            :to="column.modifier(row[column.key], row)">
                            <font-awesome-icon :icon="['fas', `${column.icon}`]"></font-awesome-icon>
                        </router-link>

                        <span v-else-if="column.type === 'html'" v-html="column.modifier(row[column.key], row)"></span>

                        <img v-else-if="column.type === 'image'" :src="row[column.key]" alt=""
                            class="img-fluid site-logo" />

                        <label v-else-if="column.type === 'radio'" class="ui-switch mt-1">
                            <input v-if="column.show && !column.show ? false : true" type="checkbox"
                                :checked="column.modifier(row[column.key], row)" class="has-value"
                                @change="$emit('changeStatus', column, row)" :disabled="column.modifier2 &&
                                        column.modifier2(row[column.key], row) == true
                                        ? true
                                        : false
                                    " />
                            <i></i>
                        </label>

                        <span v-else-if="column.type === 'status'"
                            :class="['badge ', column.modifier(row[column.key], row)]">
                            {{ row[column.key] }}
                        </span>

                        <span v-else-if="column.type === 'date'">
                            {{ dateFormat(row[column.key], column) }}
                        </span>

                        <span v-else-if="column.type === 'custom'" :class="textTruncate(column) ? 'd-inline-block text-truncate' : ''
                            " :style="textTruncate(column) ? 'max-width: 300px;' : ''">
                            {{ column.modifier(row[column.key], row) }}
                        </span>

                        <span v-else :class="textTruncate(column) ? 'd-inline-block text-truncate' : ''
                            " :style="textTruncate(column) ? 'max-width: 300px;' : ''">
                            {{ row[column.key] ? row[column.key] : "N/A" }}
                        </span>
                    </td>

                    <td v-if="data.showAction && !data.noActions.includes(row.id)">
                        <span v-for="(action, actionIndex) in data.action"
                            :key="data.name + '-action-' + rowIndex + actionIndex">
                            <a v-if="action.show && checkPermission(action, row)" class="me-2" data-toggle="tooltip"
                                data-placement="bottom" :title="action.title" href="#"
                                @click.prevent="$emit('changeAction', action, row)">
                                <font-awesome-icon :icon="['fas', action.icon]" />
                            </a>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div v-if="data.dataSet.length < 1">
        <p class="text-mute text-center sm-text">No Data Available</p>
    </div>

    <div class="table-title p-3">
        <div class="list-count d-flex align-items-center justify-content-end">
            <div class="me-2">Rows per page:</div>
            <div class="me-2">
                <select class="form-select me-2" aria-label="Default select" @change="limitChange($event)">
                    <option v-for="(item, key) in pageSize" :key="`list-count-${key}`" :value="item">
                        {{ item }}
                    </option>
                </select>
            </div>
            <div class="me-2">
                Showing {{ dataSet.from }} to {{ dataSet.to }} of
                {{ dataSet.total }} entries
            </div>

            <div v-if="data.totalRow > data.limit">
                <nav aria-label="Page navigation" class="align-items-center">
                    <ul class="pagination">
                        <li :class="['page-item', isPrevPageDisabled, 'me-3']">
                            <a class="page-link" href="#" aria-label="Previous" @click.prevent="prevPage(data.currentPage)">
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                        </li>
                        <li :class="['page-item', isNextPageDisabled]">
                            <a class="page-link" href="#" aria-label="Next" @click.prevent="nextPage(data.currentPage)">
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, watch, computed, getCurrentInstance, onMounted } from "vue";

export default {
    name: "DataTable",
    props: {
        options: {
            type: Object,
            required: false,
        },
        dataSet: {
            type: Object,
            required: false,
        },
        pageSize: {
            type: Array,
            required: false,
        },
    },
    emits: [
        "changeAction",
        "changeStatus",
        "pagination",
        "limitChange",
        "sorting",
    ],
    setup(props, { emit }) {
        let data = reactive({
            columns: [],
            name: "",
            showAction: true,
            action: [],
            dataSet: [],
            currentPage: 0,
            totalRow: 0,
            limit: 10,
            noActions: []
        });

        const dateFormat = (date, column) => {
            const { format } = column;
            const app = getCurrentInstance();
            let moment = app.appContext.config.globalProperties.$moment;
            return moment(date).format(format);
        };

        const nextPage = (page) => {
            emit("pagination", page + 1);
        };
        const prevPage = (page) => {
            emit("pagination", page - 1);
        };

        watch(
            () => props.dataSet,
            (value) => {
                if (value.data !== undefined) {
                    data.dataSet = value.data;
                    data.currentPage = value.current_page;
                    data.totalRow = value.total;
                    data.limit = value.per_page;
                }
            },
            { immediate: true }
        );

        watch(
            () => props.options,
            (value) => {
                if (value) {
                    data.columns = value.columns;
                    data.name = value.name;
                    data.showAction =
                        value.showAction === undefined ? true : value.showAction;
                    data.action = value.action === undefined ? [] : value.action;
                    data.noActions = value.noActions === undefined ? [] : value.noActions;
                }
            },
            { immediate: true }
        );

        const isPrevPageDisabled = computed(() => {
            return data.currentPage > 1 ? "" : "disabled";
        });
        const isNextPageDisabled = computed(() => {
            return data.currentPage == Math.ceil(data.totalRow / data.limit)
                ? "disabled"
                : "";
        });
        const limitChange = (event) => {
            let value = event.target.value
            if (value === 'All') value = -1;
            emit("limitChange", value);
        };

        function checkPermission(action, row) {
            if (action.modifier) {
                return action.modifier(action, row);
            } else {
                return true;
            }
        }

        const textTruncate = (action) => {
            return action.truncate ? true : false;
        };

        return {
            data,
            dateFormat,
            prevPage,
            nextPage,
            isPrevPageDisabled,
            isNextPageDisabled,
            limitChange,
            checkPermission,
            textTruncate,
        };
    },
};
/* eslint-disable */
</script>

<style scoped>
.disable-router-link {
    pointer-events: none;
    cursor: default;
}
</style>
