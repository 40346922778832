<template>
    <section>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex mb-3">
                <div class="table-box p-3">
                    <div class="border-bottom pb-3">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <button v-if="data.permission.canCreate"
                                        class="btn btn-primary" @click="openModal('showModal')">
                                    Create New
                                </button>
                            </div>
                        </div>
                    </div>

                    <data-table id="example-data-table"
                                :options="options"
                                :data-set="data.dataList"
                                :page-size="data.pageSize"
                                @change-action="changeAction"
                                @change-status="changeStatus"
                                @pagination="pagination"
                                @sorting="sorting"
                                @limit-change="limitChange"/>

                </div>
            </div>
        </div>
    </section>

    <!-- Modal -->
    <div class="modal fade" id="showModal" tabindex="-1" aria-labelledby="addRoleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Roles</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <Form ref="resetForm" @submit="formAction({url: data.setURL, modalId: 'showModal'}, data.inputFormData)"
                      v-slot="{ errors }" :validation-schema="schema">

                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12 mb-3">
                                <div class="form-group mb-3">
                                    <label class="form-control-label w-100 text-start">Name</label>
                                    <Field type="text" class="form-control" v-model="data.inputFormData.name"
                                           :class="{'border-danger': errors.name}" placeholder="Name" name="name"/>
                                    <span class="text-danger" v-if="errors.name">{{ errors.name }}</span>
                                </div>
                                <div class="form-group mb-3">
                                    <label class="form-control-label w-100 text-start">Abbreviation</label>
                                    <Field type="text" class="form-control" v-model="data.inputFormData.abbreviation"
                                           :class="{'border-danger': errors.abbreviation}" name="abbreviation"
                                           placeholder="Abbreviation"/>
                                    <span class="text-danger" v-if="errors.abbreviation">{{
                                            errors.abbreviation
                                        }}</span>
                                </div>
                                <div class="form-group mb-3">
                                    <label class="form-control-label w-100 text-start">Responsibility</label>
                                    <textarea class="form-control" rows="2"
                                              v-model="data.inputFormData.responsibility"></textarea>
                                </div>
                                <div class="form-group mb-3" v-if="data.superAdmin">
                                    <div class="custom-control custom-checkbox my-auto flex-grow-1">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1"
                                               v-model="data.inputFormData.sort"
                                               :checked="data.inputFormData.sort == 1 ? true : false">
                                        <label class="custom-control-label ms-1" for="customCheck1">This role for Super
                                            Admin Only </label>
                                    </div>
                                </div>
                                <div class="form-group mb-3">
                                    <div class="custom-control custom-checkbox my-auto flex-grow-1">
                                        <input type="checkbox" class="custom-control-input" id="customCheck2"
                                               v-model="data.inputFormData.is_project_group"
                                               :checked="data.inputFormData.is_project_group == 1 ? true : false">
                                        <label class="custom-control-label ms-1" for="customCheck2">This role for
                                            Project Management</label>
                                    </div>
                                </div>
                                <div class="form-group mb-3" v-if="data.superAdmin">
                                    <div class="custom-control custom-checkbox my-auto flex-grow-1">
                                        <input type="checkbox" class="custom-control-input" id="customCheck3"
                                               v-model="data.inputFormData.is_lead"
                                               :checked="data.inputFormData.is_lead == 1 ? true : false">
                                        <label class="custom-control-label ms-1" for="customCheck3">Project Team
                                            Leader</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary">Submit</button>
                    </div>

                </Form>
            </div>
        </div>
    </div>
    <!-- /Modal -->

</template>

<script>
import DataTable from "@/common/components/datatable";
import HelperFunction from "@/common/helpers";
import {onMounted} from "vue";
import {useStore} from "vuex";
import {Field, Form} from "vee-validate";
import * as Yup from "yup";
import {useRoute} from "vue-router";

export default {
    name: "UserRole",
    components: {
        DataTable, Form, Field
    },
    setup() {
        const route = useRoute();
        const store = useStore()
        const {
            data,
            openModal,
            getAllData,
            storeFormData,
            editFormData,
            deleteListData,
            resetForm,
            permissionResolver
        } = HelperFunction();

        /** watch **/
        permissionResolver(route.path, true);
        /** watch end **/

        const company = store.getters.getSelectedBoard
        data.superAdmin = false
        let setParams = {
            company_id: company.id
        };

        data.setURL = vueConfig.Setup.RolesEndPoint
        onMounted(async () => {
            getAllData(data.setURL, setParams)
            data.superAdmin = store.getters.getProfile.superAdmin
            //console.error(data.superAdmin, 'superAdmin')
        })

        // SET DATATABLE PROPS
        let options = {
            name: 'Roles List',
            columns: [
                {
                    title: 'Name',
                    type: 'text',
                    key: 'name',
                    sort: true
                },
                {
                    title: 'Abbreviation',
                    type: 'text',
                    key: 'abbreviation',
                    sort: true
                },
                {
                    title: 'Responsibility',
                    type: 'text',
                    key: 'responsibility',
                    truncate: true
                },
                {
                    title: 'Project Role',
                    type: 'custom',
                    key: 'is_project_group',
                    modifier: (obj, row) => {
                        return obj == 0 ? 'No' : 'Yes'
                    }
                },
                {
                    title: 'Status',
                    type: 'radio',
                    key: 'status',
                    modifier: (value, row) => {
                        return value === 'Active' ? 1 : 0;
                    },
                    modifier2: (value, row) => {
                        return !data.permission.status;
                    }
                },
                {
                    title: 'Permission',
                    type: 'icon',
                    key: 'id',
                    icon: 'lock',
                    show: data.permission.canEdit,
                    modifier: (obj, row) => {
                        return `/administrator/setup/user-permission/${row.id}`
                    }
                },
            ],
            showAction: true,
            action: [
                {
                    title: 'Edit',
                    icon: 'edit',
                    type: 'edit',
                    show: data.permission.canEdit,
                    modifier: (action, row) => {
                        return (data.superAdmin == false && row.deleteable == 0) ? false : true
                    }
                },
                {
                    title: 'Delete',
                    icon: 'trash-alt',
                    type: 'delete',
                    show: data.permission.canDelete,
                    modifier: (action, row) => {
                        return (action.type === 'delete' && row.deleteable == 0) ? false : true
                    }
                }
            ]
        }

        async function changeAction(action, row) {
            if (action.type === 'edit') {
                await editFormData({url: `${data.setURL}/${row.id}`, modalId: 'showModal'}, false)
            }
            if (action.type === 'delete') {
                deleteListData(`${data.setURL}/${row.id}`, setParams)
            }
        }

        const changeStatus = (column, row) => {
            if (row.id) {
                row._method = 'PUT'
            }
            storeFormData({url: `${vueConfig.Setup.RolesChangeStatusEndPoint}/${row.id}`}, row, false, setParams,true)
        }
        const pagination = (page) => {
            setParams.page = page
            getAllData(data.setURL, setParams)
        }
        const limitChange = (limit) => {
            setParams.limit = limit
            delete setParams.page
            getAllData(data.setURL, setParams);
        }
        const sorting = (column) => {
            setParams.column = column.key
            setParams.sort = setParams.sort == 'asc' ? 'desc' : 'asc'
            getAllData(data.setURL, setParams);
        }

        function formAction(object, inputData) {
            inputData.company_id = company.id == 0 ? null : company.id
            if (inputData.id) {
                inputData._method = 'PUT'
                object.url = `${object.url}/${inputData.id}`
            }
            //console.error(inputData); return
            storeFormData(object, inputData, false, setParams, true)
        }

        //VALIDATION
        const schema = Yup.object().shape({
            name: Yup.string().required(),
            abbreviation: Yup.string().required()
        });

        return {
            openModal, options, changeAction, changeStatus, resetForm,
            limitChange, data, pagination, formAction, schema, sorting
        }
    }
}
</script>

<style scoped>

</style>
